import React, { useEffect, useState } from 'react';
import { Modal } from "../../../shared/ui/modal";
import cls from './employerToolModal.module.sass';
import { Input } from "../../../shared/input";
import { Button } from "../../../shared/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { employerEditToolModalThunk, employerToolModalThunk } from "../model/employerToolModalThunk";
import { getEmployerData } from "../model/selectors/selectors";
import { fetchUsersData } from "../../../pages/employers/ui/employerThunk";
import {onAddAlertOptions} from "../../alert/model/slice/alertSlice";
import {Select} from "../../../shared/ui/select";
import {getRegion} from "../../../pages/employers/model/selectors/selectors";

export const EmployerToolModal = ({ open, setOpen, userId }) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [password, setPassword] = useState('1234');
    const data = useSelector(getEmployerData);
    const dispatch = useDispatch();

    const [region , setSelect] =  useState(null)

    const selectRegion = useSelector(getRegion)

    console.log(data , "data")

    useEffect(() => {
        if (userId && open) {
            dispatch(employerToolModalThunk(userId));
        }
    }, [userId, open, dispatch]);

    useEffect(() => {
        if (data && data.id === userId) {
            setName(data.name || '');
            setSurname(data.surname || '');
        }
    }, [data, userId]);

    const handleAddUser = async () => {
        if (!userId) return;

        const updatedData = {
            name: name,
            surname: surname,
            password: password,
            region: region
        };

        await dispatch(employerEditToolModalThunk({ id: userId, data: updatedData }))
        await dispatch(onAddAlertOptions({
            type: "success",
            status: true,
            msg: "Ma'lumot muvofaqqiyatli o'zgartirildi"
        }))
        await dispatch(fetchUsersData());
        setOpen(false);
    };

    return (
        <Modal
            active={open}
            setActive={setOpen}
        >
            <div className={cls.filter}>
                <h1>Ma'lumotlarni o'zgartirish</h1>
                <div className={cls.filterBox}>
                    <div className={cls.colsRaw}>
                        <Input
                            title={"Familiya"}
                            placeholder={"Familiya"}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                        <Input
                            title={"Ismi"}
                            placeholder={"Ismi"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className={cls.colsRaw}>
                        <Input
                            title={"Parol"}
                            value={password}
                            disabled
                            extraClassName={cls.disablePasswordInt}
                        />
                        <Select onChangeOption={setSelect} defaultValue={data.region}   options={selectRegion}/>
                    </div>


                    <div className={cls.buttonPanel}>
                        <Button extraClass={cls.cancel} onClick={() => setOpen(false)} children={"Bekor qilish"} />
                        <Button
                            extraClass={cls.add}
                            onClick={handleAddUser}
                            children={"O'zgartirish"}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
