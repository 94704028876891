import React, {useState} from 'react';
import {Modal} from "../../../shared/ui/modal";
import cls from './employerAddModal.module.sass'
import {Input} from "../../../shared/input";
import {Button} from "../../../shared/ui/button";
import {employerThunk} from "../../../pages/employers";
import {useDispatch, useSelector} from "react-redux";
import {Select} from "../../../shared/ui/select";
import {useSelect} from "@mui/base";
import {getRegion} from "../../../pages/employers/model/selectors/selectors";

export const EmployerAddModal = ({active, setActive}) => {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [selectRegion , setSelect] =  useState(null)
    const dispatch = useDispatch();

    const select = useSelector(getRegion)




    const handleAddUser = () => {
        const newUser = {
            name,
            surname,
            username,
            password,
            region: selectRegion
        };

        dispatch(employerThunk(newUser));
        setActive(false);
    };


    return (
        <Modal
            active={active}
            setActive={setActive}
        >
            <div className={cls.filter}>
                <h1>Yangi admin qo'shish</h1>
                <div className={cls.filterBox}>
                    <div className={cls.colsRaw}>
                        <Input
                            placeholder={"Familiya"}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                        />
                        <Input
                            placeholder={"Ismi"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className={cls.colsRaw}>
                        <Input
                            placeholder={"Login"}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <Input
                            placeholder={"Parol"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            type={"password"}
                        />

                    </div>
                    <Select onChangeOption={setSelect} options={select}/>
                    <div className={cls.buttonPanel}>
                        <Button extraClass={cls.cancel} onClick={() => setActive(false)} children={"Bekor qilish"}/>
                        <Button
                            extraClass={cls.add}
                            onClick={handleAddUser}
                            children={"Qo'shish"}
                        />

                    </div>
                </div>
            </div>
        </Modal>
    );
};
