// export const API_URL_DOC = `http://192.168.1.73:8210/`
// export const API_URL = `${API_URL_DOC}`  // api have
// export const API_FOR_IMG = `https://chat.avotra.ru`





export const API_URL_DOC = `https://chat.avotra.ru/`
export const API_URL = `${API_URL_DOC}`  // api have
export const API_FOR_IMG = `https://chat.avotra.ru`

export const headers = () => {
    const token = sessionStorage.getItem("token");
    return {
        "Authorization" : "Bearer " + token,
        'Content-Type': 'application/json'
    }
}
export const headersImg = () => {
    const token = sessionStorage.getItem("token")
    return {
        "Authorization" : "Bearer " + token
    }
}

export const useHttp = () => {
    const request = async (url, method = 'GET', body = null, headers = {'Content-Type': 'application/json'}) => {
        try {
            const response = await fetch(url, {method,mode: 'cors', body, headers, credentials: 'include'});

            if (!response.ok) {
                throw new Error(`Could not fetch ${url}, status: ${response.status}`);
            }

            return await response.json();

        } catch(e) {
            throw e;
        }
    }

    return {request}
}