import {createSlice} from "@reduxjs/toolkit";
import {employerThunk, fetchUsersData, getRegionThunk, queryFilterUserThunk} from "./employerThunk";

const initialState =
    {
        users: [],
        filtered: [],
        region: [],
        loading: false,
        error: null,
        status: 'idle',
    }

const employerSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder
            .addCase(employerThunk.pending, (state) => {
                state.loading = true
            })
            .addCase(employerThunk.fulfilled, (state, action) => {
                state.status = "success";
                state.loading = false;
                state.users.push(action.payload);
            })

            .addCase(employerThunk.rejected, (state, action) => {
                state.error = "error";
                state.error = action.error.message
            })
            .addCase(fetchUsersData.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsersData.fulfilled,( state, action )=> {
                state.loading = false;
                state.users = action.payload
                state.roomsStatus = 'success'
            })
            .addCase(fetchUsersData.rejected, state => {
                state.error = "error"
            })

            .addCase(queryFilterUserThunk.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(queryFilterUserThunk.fulfilled,( state, action )=> {
                state.loading = false;
                state.filtered = action.payload
                console.log(action.payload, "keldim")
                state.roomsStatus = 'success'
            })



            .addCase(getRegionThunk.pending, state => {
                state.error = "error"
                state.loading = true;
            })

            .addCase(getRegionThunk.fulfilled,( state, action )=> {
                state.loading = false;
                state.region = action.payload
            })
            .addCase(getRegionThunk.rejected, state => {
                state.error = "error"
            })

    }
})

export default employerSlice.reducer